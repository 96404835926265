import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, Row, Col, Input, InputNumber, Button, Progress } from 'antd';
import { ethers } from 'ethers';

import ADDRESS from '../../config/constants/contracts'
import Arrow from '../../assets/images/down-arrow.png'
import Yes from '../../assets/images/check-yes.png'
import No from '../../assets/images/check-no.png'
import IDo from '../../assets/images/ido.png'
import USDT from '../../assets/images/usdt.png'

import './ido.scss'
import useIdo from '../../hooks/useIdo';
import useInvite from '../../hooks/useInvite';
import { useWeb3Context } from '../../hooks/web3Context';
import useBalance from '../../hooks/useBalance';
import { calTime, formatNum, trim, getQueryString } from '../../utils';
import { openWarningNotification } from '../../utils/tip.js';

export default function Ido() {

	const {pathname} = useLocation()
	const [visible, setVisible] = useState(false)
	const [inviteAddress, setInviteAddress] = useState()
	const [invitePending, setInvitePending] = useState(false)
	const [inputValue, setInputValue] = useState('')
	const [caclValue, setCaclValue] = useState('')
	const [tokenBalance, setTokenBalance] = useState()
	const [coundown, setCoundown] = useState({ day: '00', hour: '00', minutes: '00', seconds: '00' })
	const [max, setMax] = useState()
	const [min, setMin] = useState()
	const [progressValue, setProgressValue] = useState(0)
	const [balance, getTokenBalance] = useBalance()
	const [pending, SetPending] = useState(false)
	const [startText, setStartText] = useState('STARTS')
	const { address, provider, chainID } = useWeb3Context()
	const { goal, price, maxPurchase, minPurchase, white, alloction, saleStart, start, participated, end, allowance, getForYou, DoClaim, DoApprove, refreshData } = useIdo()
	const { AcceptInvite } = useInvite()
	let timer = null

		useEffect(() => {
			const refreshTimer = () => {
				if(!timer) {
					timer = setInterval(() => {
						refreshData()
					}, 30000)
				}
			}
			refreshTimer()
		}, [])

		useEffect(() => {
		const address = getQueryString('address')
		if(address) {
			setInviteAddress(address)
			setVisible(true)
		}
	}, [pathname])

	useEffect(() => {
		if (maxPurchase) {
			const max = maxPurchase ? ethers.utils.formatEther(maxPurchase) : ''
			setMax(max)
		}
	}, [maxPurchase])

	useEffect(() => {
		if (minPurchase) {
			const min = minPurchase ? ethers.utils.formatEther(minPurchase) : ''
			setMin(min)
		}
	}, [minPurchase])

	useEffect(() => {
		if(alloction && goal && alloction !== 0 && goal !== 0) {
			const value = ethers.utils.formatEther(alloction) / ethers.utils.formatEther(goal) * 100
			setProgressValue(value.toFixed(2))
		}
	}, [alloction, goal])

	// useEffect(() => {
	// 	if (inputValue && inputValue >= 0) {
	// 		getForYou(inputValue).then(e => {
	// 			setCaclValue(e)
	// 		})
	// 	}
	// }, [address, provider, chainID, inputValue])

	useEffect(() => {
		if (address && chainID) {
			getTokenBalance(ADDRESS.usdt[chainID]).then(bal => {
				bal && setTokenBalance(bal)
			}).catch(e => { console.log(e) })
		}
	}, [address, provider, chainID])

	useEffect(() => {
		if (start && end) {
			setInterval(() => {
				let now = new Date().getTime()
				let startTime = start - now / 1000
				if (startTime > 0) {
					setCoundown(calTime(startTime))
				} else {
					setStartText('ENDS')
					let endTime = end - now / 1000
					if (endTime > 0) {
						setCoundown(calTime(endTime))
					} else {
						setMax(max)
					}
				}
			}, 1000)
		}
	}, [end, start, max])

	const handleCloseModal = () => {
		setVisible(false)
		window.location.href = window.location.href.split('?')[0]
		// window.location.reload()
	}

	const acceptInvite = () => {
		if(address && chainID) {
			setInvitePending(true)
			AcceptInvite(inviteAddress).then(tx => {
				setInvitePending(false)
				if(tx) {
					setVisible(false)
					window.location.href = window.location.href.split('?')[0]
					// window.location.reload()
				}
			})
		} else {
			openWarningNotification('Connect Wallet')
		}
	}

	const buy = async () => {
		let tx1
		if (allowance > 0) {
			DoClaim(inputValue).then(tx => {
				tx1 = tx
				SetPending(false)
			})
		} else {
			DoApprove().then(tx => {
				tx1 = tx
				SetPending(false)
			})
		}
	}

	const inputChange = (e) => {
		const { value } = e.target
		const inputValue = value.replace(/\D/g, '')
		setInputValue(inputValue)
		const cacl = (Number(inputValue) / 5).toFixed(2)
		setCaclValue(cacl)
	}

	const inputBlur = () => {
		const maxNumber = Number(max)
		const minNumber = Number(min)
		if(Number(inputValue) > maxNumber) {
			setInputValue(maxNumber.toString())
			const cacl = (maxNumber / 5).toFixed(2)
			setCaclValue(cacl)
		} else if(Number(inputValue) < minNumber) {
			setInputValue(minNumber.toString())
			const cacl = (minNumber / 5).toFixed(2)
			setCaclValue(cacl)
		}
	}

	return (
		<div className="ido-content" justify="center">
			<Modal visible={visible} centered={true} footer={null} onCancel={handleCloseModal}>
				<div className="invite-modal">
					<div className="invite-modal-title">Notice</div>
					<div className="flex flex-justify-content-center flex-align-items-center invite-modal-content">Please confirm the binding invitation relationship</div>
					<div className="flex flex-justify-content-center">
						<Button className="buyBtn" loading={invitePending} onClick={() => acceptInvite()}>Yes</Button>
					</div>
				</div>
			</Modal>

			<Row className="box">
				<div className="box-top flex flex-direction-column flex-justify-content-center">
					<div className="saleStarts flex flex-justify-content-center font-26 saleStarts-text">SALE {startText} IN</div>
					<Row justify="center" align="middle" className="countDown mt-10 font-weight-b">
						<Row justify="center" align="middle" className="countItem flex-direction-column">
							<Col className="time font-32 countItem-text">{coundown.day}</Col>
							<div className="time-item">Days</div>
						</Row>
						<span className="countItem-text countItem-text-symbol">:</span>
						<Row justify="center" align="middle" className="countItem flex-direction-column">
							<Col className="time font-32 countItem-text">{coundown.hour}</Col>
							<div className="time-item">Hours</div>
						</Row>
						<span className="countItem-text countItem-text-symbol">:</span>
						<Row justify="center" align="middle" className="countItem flex-direction-column">
							<Col className="time font-32 countItem-text">{coundown.minutes}</Col>
							<div className="time-item">Minutes</div>
						</Row>
						<span className="countItem-text countItem-text-symbol">:</span>
						<Row justify="center" align="middle" className="countItem flex-direction-column">
							<Col className="time font-32 countItem-text">{coundown.seconds}</Col>
							<div className="time-item">Seconds</div>
						</Row>
					</Row>
				</div>
				<div className="box-bot">
					<div className="flex flex-justify-content-between flex-align-items-center box-bot-top">
						<div className="box-bot-l">
							In DAO IDO
						</div>
						<div className="flex flex-justify-content-around flex-align-items-center box-bot-r">
							<div style={{ marginRight: 28 }}>
								<img src={white ? Yes : No} alt="" className="checkbox" /> Whitelisted
							</div>
							<div>
								<img src={participated ? Yes : No} alt="" className="checkbox" /> Participated
							</div>
						</div>
					</div>

					<div className="box-bot-top-app">
						<div className="flex flex-justify-content-between flex-align-items-center box-bot-r">
							<div className="whitelist">
								<img src={white ? Yes : No} alt="" className="checkbox" /> Whitelisted
							</div>
							<div>
								<img src={participated ? Yes : No} alt="" className="checkbox" /> Participated
							</div>
						</div>

						<div className="flex flex-justify-content-start box-bot-l indao">
							In DAO IDO
						</div>
					</div>

					<Row className="box-info">
						<div className="infoBox infoBox-l flex-1">
							<Row className="flex-direction-column font-weight-b mt-16">
								<Col className="goal-desc font-17">Fundraise goal</Col>
								<Col className="goal">{formatNum(trim(ethers.utils.formatEther(goal)))} USDT</Col>
							</Row>
							<Row className="flex-direction-column font-weight-b mt-16">
								<Col className="goal-desc font-17">Swap rate</Col>
								<Col className="goal">1 IND = {formatNum(trim(ethers.utils.formatEther(price)))} USDT</Col>
							</Row>
							<Row className="flex-direction-column font-weight-b mt-16">
								<Col className="goal-desc font-17">Progress</Col>
								<Col><Progress percent={progressValue} strokeColor="#01FDA9" showInfo={false} /></Col>
								<div className="flex flex-justify-content-between">
									<div className="percent">{progressValue}%</div>
									<div className="percent">{`${formatNum(trim(ethers.utils.formatEther(alloction)))} / ${formatNum(trim(ethers.utils.formatEther(goal)))}`}</div>
								</div>
							</Row>
							<Row className="join font-16 mt-26">
								Sale 19 Apr,6:00 - 20 Apr,6:00 UTC
							</Row>
						</div>

						<div className="infoBox infoBox-r flex-1">
							<Row className="flex-direction-column">
								<Row justify="start" className="mt-14 font-14">
									<Col className="font-weight-b balance">
										Balance:
									</Col>
									<Col className="balance-amount">
										&nbsp;{tokenBalance ? trim(ethers.utils.formatEther(tokenBalance), 2) : 0} USDT
									</Col>
								</Row>
								<Row className="mt-16 input-container">
								<Input
										width={'100%'}
										placeholder="0"
										maxLength={10}
										value={inputValue}
										onChange={inputChange}
										onBlur={inputBlur}
										controls={false}
										addonAfter={
											<div className="flex flex-justify-content-between flex-align-items-center">
												<img className="minIcon" src={USDT} alt="" />
												<div className="font-14 font-weight-b" style={{ color: '#FEFEFE' }}>
													USDT
												</div>
											</div>
										}
									/>
									{/* <InputNumber
										width={'100%'}
										placeholder="0"
										maxLength={10}
										value={inputValue}
										onChange={inputChange}
										// parser={text => /^\d+$/.test(text) ? text : Number(text).toFixed()}
										max={max}
										min={min}
										controls={false}
										addonAfter={
											<div className="flex flex-justify-content-between flex-align-items-center">
												<img className="minIcon" src={USDT} alt="" />
												<div className="font-14 font-weight-b" style={{ color: '#FEFEFE' }}>
													USDT
												</div>
											</div>
										}
									/> */}
								</Row>
								<div className="flex flex-justify-content-center flex-align-items-center" style={{ margin: '2px 0 6px' }}>
									<img src={Arrow} alt="" style={{ width: 10, height: 16 }} />
								</div>
								<Row className="disabled-input-container">
									<InputNumber
										placeholder="0"
										disabled
										maxLength={10}
										value={caclValue}
										width={'100%'}
										controls={false}
										addonAfter={
											<div className="flex flex-justify-content-between flex-align-items-center">
												<img className="minIcon" src={IDo} alt="" />
												<div className="font-14 font-weight-b" style={{ color: '#FEFEFE' }}>
													IND
												</div>
											</div>
										}
									/>
								</Row>
								<Button
									loading={pending} onClick={() => {
										SetPending(true)
										buy()
									}}
									className="confirmBtn mt-24"
									style={{
										backgroundColor: white && !participated ? '#00FFAB' : '#323232',
										color: white && !participated ? '#333' : '#8A8A8A',
									}}
									block={true}>
									{allowance > 0 ? 'CONFIRM' : "Approve USDT"}
								</Button>
							</Row>
						</div>
					</Row>
				</div>
			</Row>
		</div>
	)
}
