import { Route, Redirect, Switch } from "react-router-dom";
import { Layout } from 'antd'

import Footer from "./components/Footer/Footer";
import TopBar from "./components/TopBar";
import Ido from "./views/Ido/Ido";
import Invite from "./views/Invite/Invite";

import { getQueryString } from './utils';

function App() {
  return (
    <div className="App">
      <Layout className="flex-align-items-center" style={{'background':'none'}}>
        <div className="container">
          <TopBar />
          <Switch>
            <Route exact path="/ido">
              <Ido />
            </Route>
            <Route exact path="/">
              <Redirect to={{
                pathname: "/ido",
                search: getQueryString('address') ? `?address=${getQueryString('address')}` : ''
              }} />
            </Route>
            <Route exact path="/*">
              <Redirect to={{
                pathname: "/ido",
                search: getQueryString('address') ? `?address=${getQueryString('address')}` : ''
              }} />
            </Route>
            {/* <Route exact path="/">
              <Redirect to={{
                pathname: "/invite",
                search: getQueryString('address') ? `?address=${getQueryString('address')}` : ''
              }} />
            </Route>
            <Route exact path="/invite">
              <Invite />
            </Route> */}
          </Switch>
        </div>
        {/*  <Footer />  */}
      </Layout>
    </div>
  );
}
export default App;
