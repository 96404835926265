import { Layout, Row, Col, Image } from 'antd'
import './footer.css'
const { Footer } = Layout;

export default function FooterView() {

	return (
		<Row align="space-around" className="footerRow flex-align-items-center font-12">
			<Row className="footerList">
				<Col className="footerItem">
					© 2021 ICE Protocol
				</Col>
				<Col className="footerItem">
					ICE
				</Col>
				<Col className="footerItem">
					ICE Protocol
				</Col>
			</Row>
			<Row className="footerList">
				<Col className="footerItem">
					Twitter
				</Col>
				<Col className="footerItem">
					Telegram
				</Col>
			</Row>
		</Row>
	)
}