import { ethers } from 'ethers'
import { useEffect, useState, useCallback } from 'react'
import { useWeb3Context } from './web3Context'
import { abi as ERC20 } from '../config/abi/erc20.json'
export default function useBalance() {

    const { address, provider, chainID } = useWeb3Context()
    const [balance, setBalance] = useState(0)

    useEffect(() => {
        getBalance()
    }, [address, provider, chainID])

    const getBalance = useCallback(
        () => {
            if (provider && address) {
                provider.getBalance(address).then(rel => {
                    rel && setBalance(ethers.utils.formatEther(rel))
                }).catch(e => {

                })
            }
        },
        [address, provider, chainID],
    )

    const getTokenBalance = useCallback(
        async (tokenAddress) => {
            if (provider && address) {
                try {
                    const toeknContract = new ethers.Contract(tokenAddress, ERC20, provider)
                    const tokenBalance = await toeknContract.balanceOf(address)
                    return tokenBalance
                } catch (e) {
                //    console.log('error',e)
                }

            }
        },
        [address, provider, chainID]
    )

    return [balance, getTokenBalance];
}
