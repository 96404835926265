import React, { useState, useContext, useMemo, useCallback, useEffect } from "react";
import Web3Modal from "web3modal";
import { StaticJsonRpcProvider, Web3Provider } from "@ethersproject/providers";
import Torus from "@toruslabs/torus-embed";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { openWarningNotification, openSuccessNotification } from '../utils/tip.js';
import Portis from "@portis/web3";
import { addAvaxNet } from "../utils/index.js";
const Web3Context = React.createContext(null);
export const useWeb3Context = () => {
  const web3Context = useContext(Web3Context);
  if (!web3Context) {
    throw new Error(
      "useWeb3Context() can only be used inside of <Web3ContextProvider />, " + "please declare it at a higher level.",
    );
  }
  const { onChainProvider } = web3Context;
  return useMemo(() => {
    return { ...onChainProvider };
  }, [web3Context]);
};

export const useAddress = () => {
  const { address } = useWeb3Context();
  return address;
};

export const Web3ContextProvider = ({ children }) => {
  const [connected, setConnected] = useState(false);
  const [chainID, setChainID] = useState(56);
  // const [chainID, setChainID] = useState(97);
  const [address, setAddress] = useState("");

  const [provider, setProvider] = useState(new StaticJsonRpcProvider('https://rpc.ankr.com/bsc/'));
  //https://data-seed-prebsc-1-s1.binance.org:8545/ https://rpc.ankr.com/bsc/
  const [web3Modal, setWeb3Modal] = useState(
    new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            rpc: {
              // 97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
              56: 'https://rpc.ankr.com/bsc',
            },
          }
        },
        torus: {
          package: Torus, // required
          options: {
            networkParams: {
              host: "https://rpc.ankr.com/bsc/", // optional
              chainId: 56, // optional
            },
          }
        },
        portis: {
          package: Portis, // required
          options: {
            id: "PORTIS_ID", // required
            network: {
              nodeUrl: "https://rpc.ankr.com/bsc/",
              chainId: 56
            }
          }
        }
      },
    })
  );

  const hasCachedProvider = () => {
    if (!web3Modal) return false;
    if (!web3Modal.cachedProvider) return false;
    return true;
  };

  const _initListeners = useCallback(
    rawProvider => {
      if (!rawProvider.on) {
        return;
      }
      rawProvider.on("accountsChanged", async (accounts) => {
        setTimeout(() => window.location.reload(), 1);
      });

      rawProvider.on("chainChanged", async (chain) => {
        _checkNetwork(chain);
        setTimeout(() => window.location.reload(), 1);
      });

      rawProvider.on("network", (_newNetwork, oldNetwork) => {
        if (!oldNetwork) return;
        setTimeout(() => window.location.reload(), 1);
      });
    },
    [provider],
  );

  const _checkNetwork = (otherChainID) => {
    if (chainID !== otherChainID) {
      if (otherChainID === 56) {
        setChainID(otherChainID);
        return true;
      }
      return false;
    }
    return true;
  };

  const connect = useCallback(async () => {
    const rawProvider = await web3Modal.connect();
    _initListeners(rawProvider);

    const connectedProvider = new Web3Provider(rawProvider, "any");

    const chainId = await connectedProvider.getNetwork().then(network => network.chainId);
    const connectedAddress = await connectedProvider.getSigner().getAddress();
    const validNetwork = _checkNetwork(chainId);
    if (!validNetwork) {
      openWarningNotification("Wrong network, please switch to BSC mainnet 56");
      const tx = await addAvaxNet();
      return;
    }
    setAddress(connectedAddress);
    setProvider(connectedProvider);

    setConnected(true);

    return connectedProvider;
  }, [provider, web3Modal, connected]);

  const disconnect = useCallback(async () => {
    web3Modal.clearCachedProvider();
    setConnected(false);
    setTimeout(() => {
      window.location.reload();
    }, 1);
  }, [provider, web3Modal, connected]);
  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      connect().then(() => {
      });
    } else {
    }
  }, []);
  const onChainProvider = useMemo(
    () => ({ connect, disconnect, hasCachedProvider, provider, connected, address, chainID, web3Modal }),
    [connect, disconnect, hasCachedProvider, provider, connected, address, chainID, web3Modal],
  );
  return <Web3Context.Provider value={{ onChainProvider }}>{children}</Web3Context.Provider>;
};
