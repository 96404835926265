import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';

import './index.css'
import 'antd/dist/antd.css'
// console.log = console.warn = console.error = () => {};
ReactDOM.render(
  <>
    <Root />
  </>,
  document.getElementById('root')
);

