import { Space, Typography, Button, Row, Col, Image } from 'antd'
import { useEffect, useState } from "react";
import { useWeb3Context } from '../../hooks/web3Context'

import Wallet from '../../components/Wallet/Wallet'
import ConnectWallet from '../../components/ConnectWallet/ConnectWallet'
import downIcon from '../../assets/images/down.svg';
import bscIcon from '../../assets/images/bsc.png';
import ind from '../../assets/images/ind.png';
import './walletInfo.scss'
import { shorten } from '../../utils';

const ChainText = {
	// 97: 'BSCTEST',
	56: 'IND',
}

export default function WalletInfo() {
	const { connect, connected, web3, chainID, address } = useWeb3Context();
	const [isConnected, setConnected] = useState(connected);
	const [isShowWallet,setShowWallet] = useState(false)
	const [isShowConnectWallet,setShowConnectWallet] = useState(false)

	useEffect(() => {
		setConnected(connected);
	}, [web3, connected]);
	return (
		<div style={{ zIndex: 9999 }}>
			<Row className="info-container">
				<Col className="chain font-14 flex flex-align-items-center flex-justify-content-center">
					<Image className="bscIcon mr-8" preview={false} src={ind} />
					<div className="bacIcon-word font-weight-b">{ChainText[chainID]}</div>
				</Col>
				{address.length > 0 ?
					<Col className="address font-18 ml-20 flex flex-align-items-center flex-justify-content-center"
					onClick={ () => setShowWallet(true) }>
						<div className="font-weight-b color1">{shorten(address)}</div>
						<Image className="downIcon ml-8" preview={false} src={downIcon} />
					</Col> : ""}
				{
					!connected ?
					//  <Button className="connectBtn ml-20" onClick={ () => setShowConnectWallet(true) }>Connect Wallet</Button>
					<Button className="connectBtn ml-20" onClick={ connect }>Connect Wallet</Button>
					: ""
				}
			</Row>
			<Wallet isShowWallet={isShowWallet} setShowWallet={setShowWallet} />
			{/* <ConnectWallet isShowConnectWallet={isShowConnectWallet} setShowConnectWallet={setShowConnectWallet} /> */}
		</div>
	)
}
