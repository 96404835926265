import React, { useState, useEffect, useCallback } from 'react'
import { useWeb3Context } from './web3Context'
import { ethers } from 'ethers'
import { abi as INVITEABI } from '../config/abi/invite.json'
import ADDRESS from '../config/constants/contracts'
import { openWarningNotification, openSuccessNotification } from '../utils/tip.js';

export default function useIdo() {

	const { address, provider, chainID } = useWeb3Context()
	const [totalSale, setTotalSale] = useState()
	const [salePrice, setSalePrice] = useState()
	const [saleGcPrice, setSaleGcPrice] = useState()
	const [saleNodeNum, setSaleNodeNum] = useState()
	const [ifDiamond, setIfDiamond] = useState()
	const [gcAddress, setGcAddress] = useState()
	const [nodeAddress, setNodeAddress] = useState()
	
	useEffect(() => {
		initData().then()
	}, [address, provider, chainID])

	const initData = useCallback(
		async () => {
			if (provider && chainID) {
				try {
					//startTimestamp endTimestamp
					const inviteContract = new ethers.Contract(ADDRESS.invite[chainID], INVITEABI, provider)
					const totalSale = await inviteContract.nodeNum()
					setTotalSale(totalSale.toString())
					const salePrice = await inviteContract.salePrice()
					setSalePrice(salePrice.toString())
					const saleGcPrice = await inviteContract.saleGcPrice()
					setSaleGcPrice(saleGcPrice.toString())
					const saleNodeNum = await inviteContract.saleNodeNum()
					setSaleNodeNum(saleNodeNum.toString() - 1)
					const gcAdd = await inviteContract.gcAddress(address)
					setGcAddress(gcAdd)
					const nodeAdd = await inviteContract.nodeAddress(address)
					setNodeAddress(nodeAdd)
					
					if(address) {
						const diamond = await inviteContract.nodeAddress(address)
						setIfDiamond(diamond)
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
		[address, provider, chainID],
	)

	const AcceptInvite = useCallback(
		async (superAddress) => {
			const signer = provider.getSigner()
			const inviteContract = new ethers.Contract(ADDRESS.invite[chainID], INVITEABI, signer)
			const ifInvited = await inviteContract.memberAddress(address)
			if(ifInvited) return openWarningNotification('Already operated')
			let tx;
			try {
				tx = await inviteContract.inviteAddress(superAddress)
				await tx.wait()
				return tx
			} catch (e) {
				if (e.data) {
					openWarningNotification(e.data.message)
				} else {
					openWarningNotification(e.message)
				}
			}
		},
		[address, provider, chainID, salePrice],
	)

	const DoClaim = useCallback(
		async (superAddress) => {
			const signer = provider.getSigner()
			const inviteContract = new ethers.Contract(ADDRESS.invite[chainID], INVITEABI, signer)
			let tx;
			try {
				tx = await inviteContract.purchaseaNode(superAddress, {value: salePrice})
				await tx.wait()
				return tx
				// tx = await inviteContract.inviteAddress(superAddress)
				// await tx.wait()
				// return tx
			} catch (e) {
				if (e.data) {
					openWarningNotification(e.data.message)
				} else {
					openWarningNotification(e.message)
				}
			}
		},
		[address, provider, chainID, salePrice],
	)

	const DoClaimGc = useCallback(
		async (superAddress) => {
			const signer = provider.getSigner()
			const inviteContract = new ethers.Contract(ADDRESS.invite[chainID], INVITEABI, signer)
			let tx;
			try {
				tx = await inviteContract.purchaseaGc(superAddress, {value: saleGcPrice})
				await tx.wait()
				return tx
				// tx = await inviteContract.inviteAddress(superAddress, {value: salePrice})
				// await tx.wait()
				// return tx
			} catch (e) {
				if (e.data) {
					openWarningNotification(e.data.message)
				} else {
					openWarningNotification(e.message)
				}
			}
		},
		[address, provider, chainID, saleGcPrice],
	)

	return { totalSale, salePrice, saleGcPrice, saleNodeNum, ifDiamond, gcAddress, nodeAddress, AcceptInvite, DoClaim, DoClaimGc }
}
