export default {
  usdt: {
    // 97: '0xa0fdFd18Dc3776B997Cd52cA349C464b31F4Ab4B',
    // 56: '0x130966628846bfd36ff31a822705796e8cb8c18d',
    56: '0x55d398326f99059fF775485246999027B3197955',
  },
  ido: {
    // 97: '0xC184E21834b1b410A394435E3C49B2dCD3DCAb5c',
    // 56: '0xE0F59F14fdB367AA6ec79964EFcB519Bb94F9809'
    56: '0xFCfAacb82137ab2901B2414bCE8b06f83af4a47D'
  },
  alphaToken: {
    // 97: '0x33c5765C31b80099B6eB85b531bF8c0918BBFE0d',
    // 56: '0xEF3a9B48bE8F89847EbDA13DD47cC37151d525FC'
    56: '0xe86726506B524c3F60e2b4783123A20266BABf89'
  },
  invite: {
    // 97: '0xaEEF0AA9b49eB0256ffaC7fB8cBAd5dF871a4eD3',
    // 56: '0xaEEF0AA9b49eB0256ffaC7fB8cBAd5dF871a4eD3',
    56: '0x02f0c60665720694FE5B77A863E39b20ec0460E6',
  }
}
