import React from 'react'
import './index.scss'
import WalletInfo from './walletInfo'
import logoIcon from '../../assets/images/invite-logo.png'

export default function Index() {
    return (
        <div className="topbar flex flex-align-items-center">
            <div className="topbar-logo">
                <img src={logoIcon} alt="" />
            </div>
            <WalletInfo />
        </div>
    )
}
