import React, { useState, useEffect, useCallback } from 'react'
import { useWeb3Context } from './web3Context'
import { ethers } from 'ethers'
import { abi as IDOABI } from '../config/abi/ido.json'
import { abi as ERC20 } from '../config/abi/erc20.json'
import ADDRESS from '../config/constants/contracts'
import { openWarningNotification, openSuccessNotification } from '../utils/tip.js';
import { addTokenToWallet } from '../utils'
export default function useIdo() {

	const { address, provider, chainID } = useWeb3Context()
	const [goal, setGoal] = useState(0)
	const [price, setPrice] = useState(0)
	const [maxPurchase, setMaxPurchase] = useState(0)
	const [minPurchase, setMinPurchase] = useState(0)
	const [white, setWhite] = useState(false)
	const [alloction, setAllocation] = useState(0)
	const [saleStart, setSaleStart] = useState(false)
	const [participated, setParticipated] = useState(false)
	const [end, setEnd] = useState()
	const [allowance, setAllowance] = useState()
	const [start, setStart] = useState()
	useEffect(() => {
		initData().then()
	}, [address, provider, chainID])

	const initData = useCallback(
		async () => {
			if (provider && chainID) {
				try {
					//startTimestamp endTimestamp
					const idoContract = new ethers.Contract(ADDRESS.ido[chainID], IDOABI, provider)
					const end = await idoContract.endTimestamp()
					setEnd(end)
					const start = await idoContract.startTimestamp()
					setStart(start)
					const toTalAmount = await idoContract.toTalAmount()
					setGoal(toTalAmount)
					const alloction = await idoContract.saleAmount()
					// const alloction = await idoContract.sellAmount()
					setAllocation(alloction)
					const min = await idoContract.minAmount()
					setMinPurchase(min)
					const max = await idoContract.maxAmount()
					const maxAmount = await idoContract.publicSaleMaxAmount()
					if (end - new Date().getTime() / 1000 > 0) {
						setMaxPurchase(max)
					} else {
						setMaxPurchase(maxAmount)
					}
					const price = await idoContract.salePrice()
					setPrice(price)
					const saleStart = await idoContract.saleStarted()
					setSaleStart(saleStart)
					if (address) {
						const minContract = new ethers.Contract(ADDRESS.usdt[chainID], ERC20, provider)
						const allowance = await minContract.allowance(address, ADDRESS.ido[chainID])
						setAllowance(allowance)
						const white = await idoContract.whiteListed(address)
						setWhite(white)
						const participated = await idoContract.boughtIND(address)
						setParticipated(participated)
					}
				} catch (e) {
					// console.log(e)
				}
			}
		},
		[address, provider, chainID],
	)

	const refreshData = useCallback(
		async () => {
			if (provider && chainID) {
				try {
					const idoContract = new ethers.Contract(ADDRESS.ido[chainID], IDOABI, provider)
					const toTalAmount = await idoContract.toTalAmount()
					setGoal(toTalAmount)
					const alloction = await idoContract.saleAmount()
					setAllocation(alloction)
				} catch (e) {
					// console.log(e)
				}
			}
		},
		[address, provider, chainID],
	)

	const DoClaim = useCallback(
		async (amount) => {
			if (amount > 0) {
				const valueInWei = ethers.utils.parseUnits(amount.toString(), "ether");
				if (address && provider && chainID) {
					const signer = provider.getSigner()
					const idoContract = new ethers.Contract(ADDRESS.ido[chainID], IDOABI, signer)
					let tx;
					try {
						tx = await idoContract.purchaseaIND(valueInWei)
						await tx.wait()
						return tx
					} catch (e) {
						if (e.data) {
							openWarningNotification(e.data.message)
						} else {
							openWarningNotification(e.message)
						}
					} finally {
						if (tx) {
							initData().then()
							addTokenToWallet(ADDRESS.alphaToken[chainID]).then()
							openSuccessNotification("Successfully")
						}
					}
				}
			}
		},
		[address, provider, chainID],
	)
	const DoApprove = useCallback(
		async () => {
			if (address && provider && chainID) {
				const signer = provider.getSigner()
				const usdtContract = new ethers.Contract(ADDRESS.usdt[chainID], ERC20, signer)
				let tx;
				try {
					tx = await usdtContract.approve(ADDRESS.ido[chainID], ethers.utils.parseEther("10000000").toString())
					await tx.wait()
					return tx
				} catch (e) {
					if (e.data) {
						openWarningNotification(e.data.message)
					} else {
						openWarningNotification(e.message)
					}
				} finally {
					if (tx) {
						initData().then()
						openSuccessNotification("Approve Successfully")
					}
				}
			}
		},
		[address, provider, chainID],
	)
	const getForYou = useCallback(
		async (amount) => {
			if (provider && chainID && address && amount > 0) {
				try {
					const signer = provider.getSigner()
					const valueInWei = ethers.utils.parseUnits(amount.toString(), "ether");
					const idoContract = new ethers.Contract(ADDRESS.ido[chainID], IDOABI, signer)
					const calc = await idoContract.calculateSaleQuote(valueInWei)
					return calc
				} catch (error) {
					// console.log(error)
				}

			}
		},
		[provider, chainID],
	)


	return { goal, price, maxPurchase, minPurchase, white, start, alloction, saleStart, participated, end, allowance, getForYou, DoClaim, DoApprove, refreshData }

}


